import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Grid,
  Typography,
  TextField,
  styled,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { spacing } from "@mui/system";
import { NavLink, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SubmitButton from "../../components/Buttons/SubmitButton";
import CancelButton from "../../components/Buttons/CancelButton";
import OlogaGenericCombo from "../../components/Combos/OlogaGenericCombo";
import Item from "../../components/Combos/Item";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import useAuth from "../../hooks/useAuth";
import { Description } from "@mui/icons-material";
import { size } from "polished";
import useLookups from "../../hooks/useLookups";
import OlogaLoading from "../../components/misc/OlogaLoading";
import OlogaDownloadExcel from "../../components/misc/OlogaDownloadExcel";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const TransactionExport: React.FC<any> = ({ mode }) => {
  const [formMode] = useState(mode);
  const [showSuccess, setShowSuccess] = useState(false);
  const [targetEvents, setTargetEvents] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [txToExport, setTxToExport] = useState(0);
  const [txExported, setTxExported] = useState(0);
  const [txExistent, setTxExistent] = useState(0);
  const [txExporteFailed, setTxExporteFailed] = useState(0);
  const [exportedTransactions, setExportedTransactions] = useState(0);
  const [failedTransactions, setFailedTransactions] = useState([]);
  const [vendorId, setVendorId] = useState(0);
  const [eventId, setEventId] = useState(0);
  const { user: userContext } = useAuth();
  const { data: vendors } = useLookups("/companies/vendors/user");
  const [eventProductBatch, setEventProductBatch] = useState({
    targetEvent: null,
    vendor: null,
    imageUri: "",
  });

  const headers = [
    { field: "id", headerName: "ID" },
    { field: "totalAmount", headerName: "TOTAL AMOUNT" },
    { field: "externalTransactionCode", headerName: "EXTERNAL TX CODE" },
    { field: "transactionDate", headerName: "TX DATE" },
    { field: "status", headerName: "TX STATUS" },
    { field: "syncStatus", headerName: "SYNC STATUS" },
    { field: "syncMessage", headerName: "SYNC MESSAGE" },
  ];

  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();

  const retrieveTransactions = async (vendorId: number, eventId: number) => {
    const response = await axios.get(
      `/transactions/exportstats/${vendorId}/${eventId}`
    );

    setTxExistent((response.data as any).totalExistent);
    setTxToExport((response.data as any).totalToCreate);
    setTxExported((response.data as any).totalCreatedBefore);
    setTxExporteFailed((response.data as any).totalFailed);
  };

  const handleChangeTargetEvent = (e: Item) => {
    setEventId(e.id);
    updateVariable(eventProductBatch, "targetEvent", e);
    retrieveTransactions(vendorId, e.id);
  };

  const handleChangeVendor = async (e: Item) => {
    setVendorId(e.id);
    updateVariable(eventProductBatch, "vendor", e);
    getVendorEvents(e.id.toString());
  };

  const getVendorEvents = async (vendorId: string) => {
    setTargetEvents([]);
    if (vendorId) {
      try {
        const response = await axios.get(`/events/vendorx/${vendorId}`);
        setTargetEvents(response.data);
      } catch (error) {}
    }
  };

  const retriveTargetEvents = async () => {
    const response = await axios.get(`/events/names`);
    setTargetEvents(
      response.data.map((r: Item) => {
        return {
          id: r.id,
          description: r.name,
          name: "targetEvent",
        };
      })
    );
  };

  const onSubmit = async (data: any) => {
    if (txToExport == 0) {
      toast.warning(
        "Export will only be done if you have at least one transaction that was not exported"
      );
      return;
    }

    try {
      toast.success(
        "Transactions submitted, please wait 2-5 min, and then refresh the page to check the results."
      );

      const responsetx = await axios.post(
        `externalProvider/iqretail/transactions/upload/${vendorId}/${eventId}`
      );

      setExportedTransactions(responsetx.data.totalCreated);
      setFailedTransactions(responsetx.data.totalFailed);
    } catch (error) {
      toast.error((error as any).message);
    }
    setShowSuccess(true);
  };

  useEffect(() => {
    const vendorId = eventProductBatch?.vendor
      ? (eventProductBatch?.vendor as any)?.id
      : userContext?.vendorId;
    const eventId = (eventProductBatch?.targetEvent as any)?.id;
    setVendorId(vendorId);
    setEventId(eventId);

    if (userContext?.roleName != "ROLE_ADMIN") {
      getVendorEvents(userContext?.vendorId);
    }

    if (state && state.eventId && state.vendorId) {
      getVendorEvents(state.vendorId);
      setVendorId(state.vendorId);
      setEventId(state.eventId);
      retrieveTransactions(state.vendorId, state.eventId);
    }

    retriveTargetEvents();
    setShowSuccess(false);
  }, [formMode]);

  const updateVariable = (object: any, propertyName: string, newValue: any) => {
    const propertyPath = propertyName.split(".");
    let currentObject: any = { ...object };

    if (propertyPath.length > 1) {
      for (let i = 0; i < propertyPath.length - 1; i++) {
        currentObject = currentObject[propertyPath[i]];
      }
      currentObject[propertyPath[propertyPath.length - 1]] = newValue;
      setEventProductBatch({ ...object });
    } else if (propertyPath.length == 1) {
      currentObject[propertyName] = newValue;
      setEventProductBatch({ ...currentObject });
    }
  };

  return (
    <React.Fragment>
      <OlogaPageHeader title="Export Transactions" />
      <Card mb={12}>
        <OlogaLoading open={showLoading} />
        <CardContent>
          <form onSubmit={onSubmit}>
            <Grid container spacing={2} marginBottom={3}>
              {userContext?.roleName == "ROLE_ADMIN" && (
                <Grid item xs={6}>
                  <OlogaGenericCombo
                    label="Vendors"
                    name="vendor"
                    items={vendors}
                    value={eventProductBatch.vendor || ""}
                    onChange={handleChangeVendor}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <OlogaGenericCombo
                  label="Target Event"
                  name="targetEvent"
                  items={targetEvents}
                  value={eventProductBatch.targetEvent || ""}
                  onChange={handleChangeTargetEvent}
                />
              </Grid>
            </Grid>
            <br />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>TNX on Pagamio</TableCell>
                    <TableCell>TNX exported to IQ</TableCell>
                    <TableCell>Balance to Export</TableCell>
                    <TableCell>TNX Failed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{txExistent}</TableCell>
                    <TableCell>{txExported}</TableCell>
                    <TableCell>{txToExport}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          navigate("/iqretail/exporterrors", {
                            state: {
                              vendorId: vendorId,
                              eventId: eventId,
                              origin: "/iqretail/export",
                            },
                          });
                        }}
                      >
                        {txExporteFailed}
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            <Grid
              container
              marginTop={5}
              spacing={3}
              mt={2}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Grid item marginLeft={2} className="form-buttons">
                <SubmitButton label={"Export"} onClick={onSubmit} />
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default TransactionExport;
