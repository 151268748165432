import React, { useEffect, useState } from "react";
import { read, utils } from "xlsx";
import styled from "@emotion/styled";

import {
  Button,
  CardContent,
  Grid,
  Input,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "../../utils/axios";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import OlogaGenericCombo from "../../components/Combos/OlogaGenericCombo";
import Item from "../../components/Combos/Item";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import OlogaLoading from "../../components/misc/OlogaLoading";

interface ExcelData {
  columnName1: string;
  columnName2: number;
}

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function TransactionReverse() {
  const [show, setShow] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [data, setData] = useState<ExcelData[] | null>(null);
  const [loading, setLoading] = useState(false);

  const [fixerStats, setfixerStats] = useState({
    totalTxOnFile: 0,
    totalCreated: 0,
    totalUpdated: 0,
    totalFailed: 0,
  });

  const handleFileChange = (e: any) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          // Convert array buffer to string
          const binaryString = event.target.result as ArrayBuffer;
          const workbook = read(new Uint8Array(binaryString));
          const sheetName = workbook.SheetNames[0];
          const sheetData = utils.sheet_to_json(
            workbook.Sheets[sheetName]
          ) as ExcelData[];
          setData(sheetData);
        }
      };

      reader.readAsArrayBuffer(selectedFile);
    }
  };

  const handleFileUpload = async () => {
    if (!file) {
      toast.warning("Select a file before upload");
    }

    setLoading(true);
    const editedData = data?.map((r: any) => {
      return r["ID"];
    });

    try {
      const response = await axios.post(
        `/externalProvider/iqretail/creditnote/upload`,
        editedData
      );
      setShow(false);
      setfixerStats(response.data);
      setShow(true);
      setLoading(false);
      toast.success("Process executed, see the results bellow");
    } catch (error) {
      toast.error((error as any).message);
    }
  };

  useEffect(() => {}, []);

  function EmptyCard() {
    return <Card mb={6}></Card>;
  }

  return (
    <React.Fragment>
      <OlogaPageHeader title="Transaction Reverse" />

      <Card mb={12}>
        <OlogaLoading open={loading} />
        <CardContent>
          <form>
            <Grid item xs={6}>
              <div>
                <Input
                  type="file"
                  inputProps={{ accept: ".xlsx, .xls" }}
                  onChange={handleFileChange}
                />
              </div>
              <Grid
                container
                marginTop={5}
                spacing={3}
                mt={2}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Grid item marginRight={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleFileUpload()}
                  >
                    Upload
                  </Button>{" "}
                </Grid>
              </Grid>
            </Grid>
            <Divider my={6} />
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card mb={6}>
                  <Paper>
                    {show ? (
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">Total on File</TableCell>
                              <TableCell align="left">Total Reversed</TableCell>
                              <TableCell align="left">Tolal Failed</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                {data?.length}
                              </TableCell>
                              <TableCell align="left">
                                {fixerStats.totalCreated}
                              </TableCell>
                              <TableCell align="left">
                                {fixerStats.totalFailed}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <EmptyCard />
                    )}{" "}
                  </Paper>
                </Card>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default TransactionReverse;
