import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Fade,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { OlogaButton } from "../../components/Buttons/OlogaButton";
import { toast } from "react-toastify";
import http from "../../utils/axios";

interface CloneEventProps {
  eventId: any;
  eventName: string;
  open: boolean;
  setOpen: (value: boolean) => void;
}

function CloneEventDialog({
  eventId,
  eventName,
  open,
  setOpen,
}: CloneEventProps) {
  const [cloneProducts, setCloneProducts] = useState(false);
  const [cloneStations, setCloneStations] = useState(false);
  const [cloneMerchantAllocations, setCloneMerchantAllocations] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let name = event.target.name;
    if (name === "products") {
      setCloneProducts(event.target.checked);
    } else if (name === "stations") {
      setCloneStations(event.target.checked);
    } else if (name === "allocations") {
      setCloneMerchantAllocations(event.target.checked);
    }
  };

  const handleConfirm = async () => {
    setLoading(true);
    try {
      const values = {
        id: eventId,
        cloneProducts: cloneProducts,
        cloneStations: cloneStations,
        cloneMerchantAllocations: cloneMerchantAllocations,
      };
      console.log(values);
      await http.post("/events/clone", values);
      setLoading(false);
      toast.success("Event cloned successfully!");
      setOpen(false);
    } catch (error: any) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {}, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Clone Event</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {eventName}
          </DialogContentText>
          <FormGroup>
            <FormControlLabel
              control={<Switch name="products" onChange={handleChange} />}
              label="Clone Products"
            />
            <FormControlLabel
              control={<Switch name="stations" onChange={handleChange} />}
              label="Clone Stations"
            />
            <FormControlLabel
              control={<Switch name="allocations" onChange={handleChange} />}
              label="Clone Merchant allocations"
            />
          </FormGroup>
          <Fade in={loading} unmountOnExit>
            <CircularProgress />
          </Fade>
        </DialogContent>
        <DialogActions>
          <Box mb={3} mr={2}>
            <OlogaButton
              label="Cancel"
              variant="outlined"
              mr={2}
              onClick={handleClose}
            />
            <OlogaButton label="Confirm" onClick={handleConfirm} />
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CloneEventDialog;
