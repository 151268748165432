import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";

import { Grid } from "@mui/material";
import http from "../../utils/axios";
import OlogaButtonGroup from "../../components/Buttons/OlogaButtonGroup";
import { passwordRules, userSchema } from "../../utils/formValidation";
import { holdOn } from "../../utils/utils";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import OlogaSelect from "../../components/select/OlogaSelect";
import OlogaAlert from "../../components/misc/OlogaAlert";
import useLookups from "../../hooks/useLookups";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";

interface UserFormProps {
  user: any;
}

const UserForm = ({ user }: UserFormProps) => {
  const { user: princial } = useAuth();
  const [isAdmin] = useState(princial?.roleName === "ROLE_ADMIN");
  const [isPromoter] = useState(princial?.roleName === "ROLE_PROMOTER");
  const [isVendor] = useState(
    princial?.roleName === "ROLE_VENDOR" ||
      princial?.roleName === "ROLE_VENDOR_SUPERVISOR"
  );
  const navigate = useNavigate();
  const { data: roles } = useLookups("/users/roles");
  const { data: vendors } = useLookups("/companies/vendors/names");
  const { data: promoters } = useLookups("/companies/promoters/names");
  const [companyLabel, setCompanyLabel] = useState(
    isVendor ? "Vendor" : "Vendor/Promoter"
  );
  const [companies, setCompanies] = useState(vendors);
  const [showCompanies, setShowCompanies] = useState(false);

  const handleRoleChange = (value: any) => {
    setCompanies(value === 2 ? promoters : vendors);
    setCompanyLabel(value === 2 ? "Promoter" : "Vendor");
    setShowCompanies(value !== 1);
  };

  const handleSubmit = async (
    values: any,
    { setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      const url = "/users";
      if (user.id) {
        await http.put(url, values);
      } else {
        await http.post(url, values);
      }
      setStatus({ sent: true });
      setSubmitting(false);
      toast.success("User saved successfully");
      navigate("/users");
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({
        submit: error.message || "Error submitting form",
      });
      toast.error(error.message || "Error submitting form");
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (user?.id || !isAdmin) {
        const url = isVendor
          ? "/companies/vendors/names"
          : user.roleCode === 2
          ? "/companies/promoters/names"
          : "/companies/vendors/names";
        // handleRoleChange(user.roleCode);
        setShowCompanies(user.roleCode !== 1);
        try {
          const result = await http.get(url);
          setCompanies(result.data);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchData();
    console.log(user);
  }, [user]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const result = await http.get("/companies/vendors/names");
  //       setCompanies(result.data);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  return (
    <Formik
      initialValues={{
        ...user,
        password: user?.textPassword,
        confirmPassword: user?.textPassword,
      }}
      validationSchema={userSchema.concat(passwordRules)}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ errors, handleSubmit, status }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <OlogaTextfield name="firstName" label="First Name" />
            </Grid>
            <Grid item xs={12} md={6}>
              <OlogaTextfield name="lastName" label="Last Name" />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <OlogaTextfield name="username" label="Username" />
            </Grid>
            <Grid item xs={12} md={6}>
              <OlogaTextfield name="email" label="Email" />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <OlogaTextfield
                name="password"
                label="Password"
                // disabled={user?.id}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <OlogaTextfield
                name="confirmPassword"
                label="Confirm Password"
                // disabled={user?.id}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <OlogaSelect
                name="roleCode"
                label="Profile"
                options={roles}
                setValue={handleRoleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {showCompanies && (
                <OlogaSelect
                  name="companyId"
                  label={companyLabel}
                  options={companies}
                  disabled={!isAdmin}
                />
              )}
            </Grid>
          </Grid>
          <OlogaButtonGroup />
        </form>
      )}
    </Formik>
  );
};

export default UserForm;
