import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import UserFormComponent from "./UserForm";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import UserForm from "./UserForm";
import useAuth from "../../hooks/useAuth";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import * as XLSX from "xlsx";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import { OlogaButton } from "../../components/Buttons/OlogaButton";
import OlogaButtonGroup from "../../components/Buttons/OlogaButtonGroup";
import http from "../../utils/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useLookups from "../../hooks/useLookups";
import RemoveIcon from "@mui/icons-material/Remove";
import CheckIcon from "@mui/icons-material/Check";

interface User {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
}

interface ImportUserDTO {
  eventId: number;
  users: User[];
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const columns: GridColDef[] = [
  {
    field: "FirstName",
    headerName: "First Name",
    flex: 1,
    editable: true,
  },
  {
    field: "LastName",
    headerName: "Last Name",
    flex: 1,
    editable: false,
  },
  {
    field: "Username",
    headerName: "Username",
    flex: 1,
    editable: false,
  },
  {
    field: "Email",
    headerName: "Email",
    flex: 1,
    editable: false,
  },
  {
    field: "existing",
    headerName: "Status",
    flex: 0.5,
    editable: false,
    renderCell: (params: GridRenderCellParams) => {
      if (params.value != null) {
        return <span>{params.value ? <RemoveIcon /> : <CheckIcon />} </span>;
      }
      return <span>-</span>; // Display "Unknown" if the value is null or undefined
    },
  },
];

function ImportUsers() {
  const [file, setFile] = useState<File | null>(null);
  const [users, setUsers] = useState<any>([]);
  const { data: vendors } = useLookups("/companies/vendors/names");
  const [vendorId, setVendorId] = useState();
  const navigate = useNavigate();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const loadUsers = () => {
    if (file) {
      setUsers([]);
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
          if (e.target && e.target.result) {
            const bufferArray = e.target.result;

            const wb = XLSX.read(bufferArray, { type: "buffer" });

            const wsname = wb.SheetNames[0];

            const ws = wb.Sheets[wsname];

            const data = XLSX.utils.sheet_to_json(ws);
            // Check if data contains required fields
            const requiredFields = [
              "FirstName",
              "LastName",
              "Username",
              "Email",
            ];

            const hasRequiredFields = data.every((item: any) =>
              requiredFields.every((field) => field in item)
            );

            if (!hasRequiredFields) {
              // reject(
              //   new Error(
              //     "The dataset does not contain all required fields: firstName, lastName, username, and email."
              //   )
              // );
              toast.error(
                "Invalid dataset. The file must have the following headers: FirstName, LastName, Username, Email."
              );
              return;
            }

            resolve(data);
          }
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });

      promise.then((d) => {
        // setItems(d);
        setUsers(d);
        console.log(d);
      });
    }
  };

  const handleSubmit = async () => {
    try {
      const data = {
        eventId: "",
        vendorId: vendorId,
        users: users,
      };
      console.log(data);
      // const response = await http.post("/users/import", data);
      // setUsers(response.data);
      toast.success("Users imported successfully");
      // navigate("/users");
    } catch (error: any) {
      toast.error(error.message || "Error submitting form");
    }
  };

  return (
    <>
      <OlogaPageHeader
        title="Import Users"
        items={[{ label: "Users", url: "/users" }]}
      />
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={6} mb={4}>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    options={vendors}
                    getOptionLabel={(option: any) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Vendor"
                        variant="outlined"
                      />
                    )}
                    onChange={(event, value) => {
                      setVendorId(value?.id);
                      setUsers([]);
                      setFile(null);
                    }}
                  />
                </Grid>
                {vendorId && (
                  <>
                    <Grid item xs={12} md={2}>
                      <input
                        type="file"
                        accept=".xlsx, .xls, .csv"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                        id="file-input"
                      />
                      <label htmlFor="file-input">
                        <Button
                          variant="outlined"
                          component="span"
                          fullWidth={true}
                        >
                          Select File
                        </Button>
                      </label>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      {file && (
                        <TextField
                          value={file.name}
                          disabled
                          variant="standard"
                          fullWidth
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={loadUsers}
                        disabled={!file}
                      >
                        Load Users
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
              <OlogaDataGrid
                existingData={users}
                columns={columns}
                paginationMode="client"
              />
              <Grid item xs={12}>
                <OlogaButtonGroup>
                  {" "}
                  <OlogaButton
                    label="Import Users"
                    type="button"
                    onClick={handleSubmit}
                    disabled={users.length == 0}
                  />
                </OlogaButtonGroup>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default ImportUsers;
