import React, { useState, useEffect, ChangeEvent } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import {
  Card as MuiCard,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/Edit";
import { spacing } from "@mui/system";
import axios from "../../utils/axios";
import { Trash2 } from "react-feather";
import { toast } from "react-toastify";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import { Formik } from "formik";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import OlogaSelect from "../../components/select/OlogaSelect";
import { OlogaButton } from "../../components/Buttons/OlogaButton";
import SearchIcon from "@mui/icons-material/Search";
import useLookups from "../../hooks/useLookups";
import OlogaAutocomplete from "../../components/select/OlogaAutocomplete";

const Card = styled(MuiCard)(spacing);

function EventProductList() {
  const { data: events } = useLookups("/events/names");
  const { data: categories } = useLookups(
    "/simple-entity/product-sub-categories/vendor"
  );
  const { data: brands } = useLookups("/simple-entity/product-brands/vendor");
  const [url, setUrl] = useState("/eventProduct/q?");
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "imageUrl",
      headerName: "Image",
      flex: 0.3,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <img
          src={params.row.imageUri}
          width="30px"
          height="auto"
          alt="product-img"
        />,
      ],
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.8,
      editable: false,
    },
    {
      field: "categoryName",
      headerName: "Category",
      flex: 0.5,
      editable: false,
    },
    {
      field: "brandName",
      headerName: "Brand",
      flex: 0.3,
      editable: false,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 0.3,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <span>
          {params.row.price?.toLocaleString("en-ZA", {
            style: "currency",
            currency: "ZAR",
          })}
        </span>,
      ],
    },
    {
      field: "cost",
      headerName: "Cost",
      flex: 0.3,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <span>
          {params.row.cost?.toLocaleString("en-ZA", {
            style: "currency",
            currency: "ZAR",
          })}
        </span>,
      ],
    },
    {
      field: "eventName",
      headerName: "Event",
      flex: 0.7,
      editable: false,
    },
    {
      field: "sizesText",
      headerName: "Sizes",
      flex: 0.5,
      editable: false,
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 0.3,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            navigate("/products/edit", {
              state: {
                eventProductId: params.id,
                backRoute: "/products/search",
              },
            });

            console.log("products", params);
          }}
          label="Detail"
        />,
      ],
    },
    {
      field: "hide",
      headerName: "Visible",
      flex: 0.3,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={params.row.state}
                onChange={() => {
                  try {
                    const response = axios.post(
                      `/eventProduct/changelifecycle/${params.id}`
                    );
                    toast.success("Producto alterado com sucesso");
                    navigate("/products/search");
                  } catch (error) {
                    toast.error((error as any).message);
                  }
                }}
              />
            }
            label=""
          />
        </FormGroup>,
      ],
    },
  ];

  const handleSubmit = async (values: any) => {
    try {
      let q = "";
      for (let key in values) {
        if (values.hasOwnProperty(key)) {
          let value = values[key];
          if (value) {
            q += key + "=" + value + "&";
          }
        }
      }
      setUrl(
        q.length > 0 ? "/eventProduct/q?" + q.slice(0, -1) : "/eventProduct/q?"
      );
    } catch (error: any) {
      toast.error(error.message || "Error submitting form");
    }
  };

  useEffect(() => {}, [url]);
  return (
    <React.Fragment>
      <OlogaPageHeader
        title="Products"
        buttonLabel="New Product"
        onClick={() => navigate("/products/create")}
      />

      <Grid container>
        <Grid item xs={12}>
          <Formik initialValues={{}} enableReinitialize onSubmit={handleSubmit}>
            {({ handleSubmit, status }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={4} mb={3}>
                  <Grid item xs={12} md={3}>
                    <OlogaTextfield name="name" label="Product Name" />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <OlogaAutocomplete
                      name="categoryId"
                      label="Category"
                      options={categories}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <OlogaAutocomplete
                      name="brandId"
                      label="Brand"
                      options={brands}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <OlogaAutocomplete
                      name="eventId"
                      label="Event"
                      options={events}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <OlogaButton
                      label="Search"
                      type="submit"
                      icon={<SearchIcon />}
                      mt={3}
                      size="large"
                    />
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
          <Card>
            <OlogaDataGrid
              url={url}
              columns={columns}
              exportToExcel={true}
              fileName="Event Products"
              excludeFromExport={["imageUrl", "edit", "actions", "hide"]}
            />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default EventProductList;
