import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Grid,
  Typography,
  TextField,
  styled,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import { spacing } from "@mui/system";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SubmitButton from "../../components/Buttons/SubmitButton";
import CancelButton from "../../components/Buttons/CancelButton";
import Item from "../../components/Combos/Item";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import useAuth from "../../hooks/useAuth";
import { Description } from "@mui/icons-material";
import { size } from "polished";
import OlogaLoading from "../../components/misc/OlogaLoading";
import OlogaAutocomplete from "../../components/select/OlogaAutocomplete";
import { Formik } from "formik";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const EventProductBatchEdit: React.FC<any> = ({
  mode,
  url,
  successMessage,
  onSucessMessage,
}) => {
  const [formMode] = useState(mode);
  const [showSuccess, setShowSuccess] = useState(false);
  const [fromEvents, setFromEvents] = useState([]);
  const [targetEvents, setTargetEvents] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [productBrands, setProductBrands] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const { user } = useAuth();

  const [eventProductBatch, setEventProductBatch] = useState({
    brand: "",
    fromEvent: {
      id: null,
      value: null,
      name: null,
    },
    targetEvent: {
      id: null,
      value: null,
      name: null,
    },
    imageUri: "",
  });

  const navigate = useNavigate();
  const params = useParams();

  const retrieveAllProducts = async (
    originAction: string,
    actionObjectId: string
  ) => {
    const response =
      originAction === "brand"
        ? await axios.get(
            "/eventProduct/brand/" +
              actionObjectId +
              "/event/" +
              (eventProductBatch.fromEvent as any).id
          )
        : await axios.get("eventProduct/event/" + actionObjectId);

    setAllProducts(
      response.data.map((r: any) => ({
        id: eventProductBatch.fromEvent.id === "" ? "" : r.id,
        selected: "off",
        productId: r.productId,
        productDescription:
          eventProductBatch.fromEvent.id === ""
            ? r.description
            : r.productDescription,
        price: r.price,
        cost: r.cost,
        quantity: r.quantity,
        imageUri: r.imageUri,
        sizes: r.sizes,
      }))
    );
  };

  const retriveFromEvents = async () => {
    const response = await axios.get(`/events/names`);
    setFromEvents(
      response.data.map((r: Item) => {
        return {
          id: r.id,
          description: r.name,
          name: r.name,
        };
      })
    );
  };

  const retriveTargetEvents = async () => {
    const response = await axios.get(`/events/names`);
    setTargetEvents(
      response.data.map((r: Item) => {
        return {
          id: r.id,
          description: r.name,
          name: r.name,
        };
      })
    );
  };

  const retriveProductBrands = async () => {
    const response = await axios.get("/simple-entity/product-brands/vendor");
    setProductBrands(
      response.data.map((r: Item) => {
        return {
          id: r.id,
          description: r.description,
          name: r.description,
        };
      })
    );
  };

  const onSubmit = async (data: any) => {
    setShowLoading(true);
    try {
      const response = await axios.post(
        `eventProduct/batch/${user?.vendorId}/${eventProductBatch.targetEvent.id}`,
        allProducts.filter((r: any) => r.selected === "on")
      );

      toast.success("Products replicated sucessfully");
      setShowSuccess(true);
      navigate("/products/search", { replace: true });
    } catch (error) {
      toast.error((error as any).message);
    }
    setShowLoading(false);
  };

  const setFormEvent = (event: Item) => {
    if (!event) {
      return;
    }
    updateVariable(eventProductBatch, "fromEvent", event);
    retrieveAllProducts("fromEvent", event.id.toString());
  };

  const setBrand = (event: Item) => {
    if (!event) {
      return;
    }
    updateVariable(eventProductBatch, "brand", event);
    retrieveAllProducts("brand", event.id.toString());
  };

  const setTargetEvent = (event: Item) => {
    if (!event) {
      return;
    }
    updateVariable(eventProductBatch, "targetEvent", event);
  };

  const setTableFields = (e: any) => {
    const editedProducts = [] as any;
    allProducts.map((item: any) => {
      if (e.target.id === "s-" + item.id || e.target.id === "s-all") {
        item[e.target.name] = item[e.target.name] === "on" ? "off" : "on";
      }
      if (e.target.id === "q-" + item.id) {
        item[e.target.name] = e.target.value;
      }
      if (e.target.id === "p-" + item.id) {
        item[e.target.name] = e.target.value;
      }
      if (e.target.id === "c-" + item.id) {
        item[e.target.name] = e.target.value;
      }
      if (e.target.id.includes("qs-" + item.id)) {
        item.sizes.map((sizeItem: any) => {
          if ("qs-" + item.id + "-" + sizeItem.sizeId === e.target.id) {
            sizeItem["quantity"] = e.target.value;
          }
        });
      }
      editedProducts.push(item);
    });

    setAllProducts(editedProducts as any);
  };

  useEffect(() => {
    retriveFromEvents();
    retriveTargetEvents();
    retriveProductBrands();
    setShowSuccess(false);
  }, [formMode]);

  const updateVariable = (object: any, propertyName: string, newValue: any) => {
    const propertyPath = propertyName.split(".");
    let currentObject: any = { ...object };

    if (propertyPath.length > 1) {
      for (let i = 0; i < propertyPath.length - 1; i++) {
        currentObject = currentObject[propertyPath[i]];
      }
      currentObject[propertyPath[propertyPath.length - 1]] = newValue;
      setEventProductBatch({ ...object });
    } else if (propertyPath.length == 1) {
      currentObject[propertyName] = newValue;
      setEventProductBatch({ ...currentObject });
    }
  };

  return (
    <React.Fragment>
      <OlogaPageHeader
        title="Allocations"
        items={[{ label: "Products", url: "/products/search" }]}
      />
      <Card mb={12}>
        <OlogaLoading open={showLoading} />
        <CardContent>
          <Formik initialValues={{}} onSubmit={onSubmit}>
            {({
              handleSubmit,
              values,
              status,
              errors,
              touched,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} marginBottom={3}>
                  <Grid item xs={6}>
                    <OlogaAutocomplete
                      name="fromEvent"
                      options={fromEvents}
                      label="From Event"
                      initValue={eventProductBatch.fromEvent.name || ""}
                      handleChange={setFormEvent}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <OlogaAutocomplete
                      name="brand"
                      options={productBrands}
                      label="Select a Brand"
                      initValue={eventProductBatch.brand || ""}
                      handleChange={setBrand}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <OlogaAutocomplete
                      name="targetEvent"
                      options={targetEvents}
                      label="Target Event"
                      initValue={eventProductBatch.targetEvent?.name || ""}
                      handleChange={setTargetEvent}
                    />
                  </Grid>
                </Grid>
                <br />
                <br />
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Checkbox
                            id={"s-all"}
                            name="selected"
                            size="small"
                            onChange={setTableFields}
                          />
                        </TableCell>
                        <TableCell>Image</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Cost</TableCell>
                        <TableCell>Stock</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allProducts.map(
                        ({
                          id,
                          selected,
                          productDescription,
                          price,
                          cost,
                          quantity,
                          imageUri,
                          sizes,
                        }) => (
                          <TableRow key={id + productDescription + price}>
                            <TableCell>
                              <Checkbox
                                id={"s-" + id}
                                name="selected"
                                size="small"
                                onChange={setTableFields}
                                checked={selected === "on"}
                              />
                            </TableCell>
                            <TableCell width={30}>
                              <img
                                src={imageUri}
                                width="70px"
                                height="auto"
                                alt="product-img"
                              />
                            </TableCell>
                            <TableCell>{productDescription}</TableCell>
                            <TableCell>
                              <TextField
                                id={"p-" + id}
                                name="price"
                                value={price || ""}
                                onChange={setTableFields}
                                required
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                id={"c-" + id}
                                name="cost"
                                value={cost || ""}
                                onChange={setTableFields}
                                required
                              />
                            </TableCell>
                            <TableCell>
                              {(sizes as []).length == 0 && ( //quantity > 0 && (
                                <TextField
                                  id={"q-" + id}
                                  name="quantity"
                                  label="Quantity"
                                  value={quantity || ""}
                                  onChange={setTableFields}
                                  required
                                />
                              )}
                              {(sizes as []).length > 0 &&
                                (sizes as []).map(
                                  ({ sizeId, description, quantity }) => (
                                    <Grid item xs={12} marginBottom={3}>
                                      <TextField
                                        id={"qs-" + id + "-" + sizeId}
                                        name="sizes"
                                        label={description + " - Quantity"}
                                        value={quantity || ""}
                                        onChange={setTableFields}
                                        required
                                      />
                                    </Grid>
                                  )
                                )}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <br />
                <Grid
                  container
                  marginTop={5}
                  spacing={3}
                  mt={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Grid item marginLeft={2} className="form-buttons">
                    <SubmitButton label={"Save"} onClick={onSubmit} />
                  </Grid>
                  <Grid item marginRight={2}>
                    <CancelButton navigateTo={"../search"} />
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default EventProductBatchEdit;
