import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Protected routes
import EventProductList from "./pages/products/EventProductList";
import Users from "./pages/auth/Users";
import Orders from "./pages/finance/Orders";
import Sales from "./pages/finance/Sales";
import BankRecon from "./pages/finance/BankRecon";
import BankStatementUpload from "./pages/finance/BankStatementUpload";
import VenueList from "./pages/venue/VenueList";
import Profile from "./pages/auth/Profile";
import BankReconDetail from "./pages/finance/BankReconDetail";
import SalesDetail from "./pages/finance/SalesDetail";
import OrdersDetail from "./pages/finance/OrdersDetails";
import EventProductEdit from "./pages/products/EventProductEdit";
import CreateStation from "./pages/station/CreateStation";
import CreateEventPage from "./pages/events/CreateEvent";
import StockTransferPage from "./pages/Stock/StockTransferPage";
import EventProductBatchEdit from "./pages/products/EventProductBatchEdit";
import CreateVenue from "./pages/venue/CreateVenue";
import UpdateVenue from "./pages/venue/UpdateVenue";
import StationList from "./pages/station/StationList";
import UpdateStation from "./pages/station/UpdateStation";
import CreateUser from "./pages/auth/CreateUser";
import UpdateUser from "./pages/auth/UpdateUser";
import EventList from "./pages/events/EventList";
import UpdateEvent from "./pages/events/UpdateEvent";
import MerchantAllocation from "./pages/events/MerchantAllocation";
import VendorList from "./pages/company/VendorList";
import UpdateVendor from "./pages/company/UpdateVendor";
import SettingsForm from "./pages/settings/SettingsForm";
import SettingsList from "./pages/settings/SettingsList";
import CreateVendor from "./pages/company/CreateVendor";
import PromoterList from "./pages/company/PromoterList";
import CreatePromoter from "./pages/company/CreatePromoter";
import UpdatePromoter from "./pages/company/UpdatePromoter";
import WorldTourList from "./pages/tours/WorldTourList";
import CountryTourList from "./pages/tours/CountryTourList";
import CreateCountryTour from "./pages/tours/CreateCountryTour";
import UpdateCountryTour from "./pages/tours/UpdateCountryTour";
import CreateWorldTour from "./pages/tours/CreateWorldTour";
import UpdateWorldTour from "./pages/tours/UpdateWorldTour";
import SignOut from "./components/auth/SignOut";
import StockList from "./pages/Stock/StockList";
import ManageVendorEvent from "./pages/events/ManageVendorEvent";
import CommissionPage from "./pages/commission/Commission";
import EventStationProductBatchEdit from "./pages/products/EventStationProductBatchEdit";
import StockReconcilePage from "./pages/Stock/StockReconcilePage";
import ImportUsers from "./pages/auth/ImportUsers";
import EventProductImport from "./pages/products/EventProductImport";
import TransactionExport from "./pages/finance/TransactionExport";
import TransactionReverse from "./pages/finance/TransactionReverse";
import IntegrationProvidersPage from "./pages/integrations/IntegrationProvidersPage";
import IntegrationProviderDetailsPage from "./pages/integrations/IntegrationProviderDetailsPage";
import EventProductImportErrorList from "./pages/products/EventProductImportErrorList";
import TransactionExportErrorList from "./pages/finance/TransactionExportErrorList";

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));

const routes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Default />,
      },
    ],
  },
  {
    path: "products",
    element: <DashboardLayout />,
    children: [
      {
        path: "search",
        element: <EventProductList />,
      },
      {
        path: "create",
        element: <EventProductEdit />,
      },
      {
        path: "edit",
        element: <EventProductEdit />,
        param: "eventProductId",
      },
      {
        path: "multiple-allocation",
        element: <EventProductBatchEdit />,
      },
      {
        path: "station-multiple-allocation",
        element: <EventStationProductBatchEdit />,
      },
    ],
  },
  {
    path: "iqretail",
    element: <DashboardLayout />,
    children: [
      {
        path: "import",
        element: <EventProductImport />,
      },
      {
        path: "importerrors",
        element: <EventProductImportErrorList />,
      },
      {
        path: "export",
        element: <TransactionExport />,
      },
      {
        path: "exporterrors",
        element: <TransactionExportErrorList />,
      },
      {
        path: "txreverse",
        element: <TransactionReverse />,
      },
    ],
  },
  {
    path: "settings",
    element: <DashboardLayout />,
    children: [
      {
        path: "create",
        element: <SettingsForm />,
      },
      {
        path: "search",
        element: <SettingsList />,
      },
      {
        path: "edit",
        element: <SettingsForm />,
        param: "settingId",
      },
    ],
  },
  {
    path: "integrations",
    element: <DashboardLayout />,
    children: [
      { path: "providers", element: <IntegrationProvidersPage /> },
      { path: "details", element: <IntegrationProviderDetailsPage /> },
    ],
  },
  {
    path: "stock",
    element: <DashboardLayout />,
    children: [
      {
        path: "list",
        element: <StockList />,
      },
      {
        path: "reconcile",
        element: <StockReconcilePage />,
      },
      {
        path: "transfer",
        element: <StockTransferPage />,
      },
    ],
  },
  {
    path: "events",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <EventList />,
      },
      {
        path: "add-event",
        element: <CreateEventPage />,
      },
      {
        path: "edit-event",
        element: <UpdateEvent />,
      },
      {
        path: "manage-event",
        element: <ManageVendorEvent />,
      },
      {
        path: "merchant-allocation",
        element: <MerchantAllocation />,
      },
      {
        path: "stations",
        element: <StationList />,
      },
      {
        path: "add-station",
        element: <CreateStation />,
      },

      {
        path: "edit-station",
        element: <UpdateStation />,
      },
      {
        path: "venues",
        element: <VenueList />,
      },
      {
        path: "add-venue",
        element: <CreateVenue />,
      },
      {
        path: "edit-venue",
        element: <UpdateVenue />,
      },
      {
        path: "vendors",
        element: <VendorList />,
      },
      {
        path: "add-vendor",
        element: <CreateVendor />,
      },
      {
        path: "edit-vendor",
        element: <UpdateVendor />,
      },
      {
        path: "promoters",
        element: <PromoterList />,
      },
      {
        path: "add-promoter",
        element: <CreatePromoter />,
      },
      {
        path: "edit-promoter",
        element: <UpdatePromoter />,
      },

      {
        path: "country-tours",
        element: <CountryTourList />,
      },
      {
        path: "add-country-tour",
        element: <CreateCountryTour />,
      },
      {
        path: "edit-country-tour",
        element: <UpdateCountryTour />,
      },

      {
        path: "world-tours",
        element: <WorldTourList />,
      },
      {
        path: "add-world-tour",
        element: <CreateWorldTour />,
      },
      {
        path: "edit-world-tour",
        element: <UpdateWorldTour />,
      },
    ],
  },
  {
    path: "finance",
    element: <DashboardLayout />,
    children: [
      {
        path: "bank-statement-upload",
        element: <BankStatementUpload />,
      },
      {
        path: "recon",
        element: <BankRecon />,
      },
      {
        path: "re-orders",
        element: <Orders />,
      },
      {
        path: "sales",
        element: <Sales />,
      },
      {
        path: "recondetail",
        element: <BankReconDetail />,
      },
      {
        path: "saledetail",
        element: <SalesDetail />,
      },
      {
        path: "orderdetail",
        element: <OrdersDetail />,
      },
      {
        path: "commission",
        element: <CommissionPage />,
      },
    ],
  },
  {
    path: "users",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Users />,
      },
      {
        path: "import-users",
        element: <ImportUsers />,
      },
      {
        path: "add-user",
        element: <CreateUser />,
      },
      {
        path: "edit-user",
        element: <UpdateUser />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "logout",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <SignOut />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
