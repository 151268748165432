import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Item from "../../components/Combos/Item";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import OlogaGenericCombo from "../../components/Combos/OlogaGenericCombo";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Autocomplete,
  TextField,
} from "@mui/material";

import axios from "../../utils/axios";

import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import useLookups from "../../hooks/useLookups";
import { OlogaButton } from "../../components/Buttons/OlogaButton";
import useAuth from "../../hooks/useAuth";
import { GridActionsCellItem } from "@mui/x-data-grid";
import StockDetailModal from "./StockDetailModal";
import OlogaLoading from "../../components/misc/OlogaLoading";
import OlogaDownloadExcel from "../../components/misc/OlogaDownloadExcel";

const StockList: React.FC<any> = () => {
  const initialFormData = {
    stationVendor: null,
    stationEvent: null,
    station: null,
  };

  const { data: vendors } = useLookups("/companies/vendors/user");
  const [vendorEvents, setVendorEvents] = useState([]);
  const [eventProducts, setEventProducts] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [formData, setFormData] = useState(initialFormData);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedEventProductMetadata, setSelectedEventProductMetadata] =
    useState<any>({});
  const [selectedEventId, setSelectedEventId] = useState("");
  const [selectedEventName, setSelectedEventName] = useState("");
  const [selectedStationId, setSelectedStationId] = useState("");
  const { user: userContext, isAdmin } = useAuth();
  const [loading, setLoading] = useState(false);
  const [stations, setStations] = useState([]);

  useEffect(() => {
    if (!userContext?.isAdmin) getVendorEvents(userContext?.vendorId);
  }, [userContext]);

  const getVendorEvents = async (vendorId: string) => {
    setVendorEvents([]);
    if (vendorId) {
      try {
        const response = await axios.get(`/events/names`);
        setVendorEvents(response.data);
      } catch (error) {}
    }
  };

  const getStations = async (eventId: string, vendorId: string) => {
    setStations([]);
    if (eventId && vendorId) {
      try {
        const response = await axios.get(
          `/stations/event/${eventId}/vendor/${vendorId}/names`
        );
        setStations(response.data);
      } catch (error) {}
    }
  };

  const exportReport = () => {
    // exportToExcel();
  };

  const headers = [
    { field: "eventProductId", headerName: "ID" },
    { field: "productBrand", headerName: "BRAND/DEP" },
    { field: "productDescription", headerName: "PRODUCT NAME" },
    { field: "productPrice", headerName: "SELLING PRICE" },
    { field: "stationName", headerName: "STATION NAME" },
    { field: "openingStock", headerName: "OPENING STOCK" },
    { field: "transferIn", headerName: "TRANSFER IN" },
    { field: "transferOut", headerName: "TRANSFER OUT" },
    // { field: "openingStock", headerName: "GROSS INITIAL STOCK" },
    { field: "finalOpeningStock", headerName: "FINAL OPENING STOCK" },
    { field: "closingStockManual", headerName: "MANUAL CLOSING STOCK" },
    { field: "closingStock", headerName: "CLOSING STOCK" },
    { field: "quantitySold", headerName: "QUANTITY SOLD" },
    { field: "difference", headerName: "DIFFERENCE" },
    { field: "grossSalesVat", headerName: "GROSS SALES (incl. vat)" },
    { field: "grossSalesNoVat", headerName: "GROSS SALES (excl. vat)" },
    { field: "productCost", headerName: "COST (excl. vat)" },
    { field: "profit", headerName: "PROFIT (excl. vat)" },
  ];

  const getEventProductStock = async (eventId: string, stationId: string) => {
    setEventProducts([]);
    setReportData([]);
    // const response = await axios.get(
    //   `/stock/reconcile/aggregated/event/${eventId}/vendor/${userContext?.vendorId}`
    // );
    // console.log(response.data);
    // setEventProducts(
    //   response.data.map((r: any) => {
    //     return {
    //       id: r.eventProductId,
    //       name: r.productDescription,
    //       imageUri: r.imageUri,
    //       transferIn: r.transferIn,
    //       transferOut: r.transferOut,
    //       closingStock: r.closingStock,
    //       closingStockManual: r.closingStockManual,
    //       difference: r.difference,
    //       quantitySold: r.quantitySold,
    //     };
    //   })
    // );

    const vatRate = 0.15;

    let url = `/stock/reconcile/event/${eventId}/vendor/${userContext?.vendorId}`;
    const report = await axios.get(
      url + (stationId ? `/station/${stationId}` : "")
    );
    setReportData(
      report.data.map((r: any) => {
        return {
          eventProductId: r.eventProductId,
          productDescription: r.productDescription,
          stationName: r.stationName,
          stationId: r.stationId,
          imageUri: r.imageUri,
          productSizeDescription: r.productSizeDescription,
          productBrand: r.productBrand,
          productSizeId: r.productSizeId,
          productPrice: r.productPrice,
          productCost:
            (r.productCost ? r.productCost : 0 * r.quantitySold) /
            (1 + vatRate),
          openingStock: r.openingStock,
          transferIn: r.transferIn,
          transferOut: r.transferOut,
          finalOpeningStock: r.finalOpeningStock,
          closingStockManual: r.closingStockManual,
          closingStock: r.closingStock,
          quantitySold: r.quantitySold,
          difference: r.closingStockManual - r.closingStock,
          grossSalesVat: r.quantitySold * r.productPrice,
          grossSalesNoVat: (r.quantitySold * r.productPrice) / (1 + vatRate),
          profit: calcProfit(
            r.quantitySold,
            r.productCost,
            r.productPrice,
            vatRate
          ),
        };
      })
    );
  };

  const calcProfit = (
    quantitySold: number,
    cost: number,
    price: number,
    vatRate: number
  ) => {
    const costExclVat = (cost ? cost : 0 * quantitySold) / (1 + vatRate);
    const grossSales = (price * quantitySold) / (1 + vatRate);
    return grossSales - costExclVat;
  };

  const handleChangeVendor = async (e: Item) => {
    updateVariable(formData, "stationVendor", e);
    getVendorEvents(e.id.toString());
  };

  const handleChangeEvent = async (e: Item) => {
    updateVariable(formData, "station", null);
    setStations([]);
    if (e) {
      setSelectedEventId(e.id.toString());
      setSelectedEventName(e.name);
      getEventProductStock(e.id.toString(), selectedStationId);
      getStations(e.id.toString(), userContext?.vendorId);
    } else {
      setEventProducts([]);
    }
  };

  const handleChangeStation = async (e: Item) => {
    updateVariable(formData, "station", e);
    setSelectedStationId(e.id.toString());
    getEventProductStock(selectedEventId, e.id.toString());
  };

  const updateVariable = (object: any, propertyName: string, newValue: any) => {
    const propertyPath = propertyName.split(".");
    let currentObject: any = { ...object };

    if (propertyPath.length > 1) {
      for (let i = 0; i < propertyPath.length - 1; i++) {
        currentObject = currentObject[propertyPath[i]];
      }
      currentObject[propertyPath[propertyPath.length - 1]] = newValue;
      setFormData({ ...object });
    } else if (propertyPath.length == 1) {
      currentObject[propertyName] = newValue;
      setFormData({ ...currentObject });
    }
  };

  return (
    <React.Fragment>
      <OlogaPageHeader
        title="Stock List"
        items={[{ label: "Products", url: "/products/search" }]}
      />
      <Card>
        <CardContent>
          <form>
            <Grid container spacing={2} marginBottom={3}>
              {isAdmin && (
                <Grid item xs={6}>
                  <OlogaGenericCombo
                    name="stationVendor"
                    label="Vendors"
                    items={vendors}
                    value={formData.stationVendor || ""}
                    onChange={handleChangeVendor}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <Autocomplete
                  options={vendorEvents}
                  getOptionLabel={(option: any) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} label="Events" variant="outlined" />
                  )}
                  onChange={(event, value) => {
                    handleChangeEvent(value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <OlogaGenericCombo
                  name="station"
                  label="Station"
                  items={stations}
                  value={formData.station || ""}
                  onChange={handleChangeStation}
                />
              </Grid>
            </Grid>
            {selectedEventId && (
              <Grid container spacing={2} marginBottom={3}>
                <Grid item xs={2}>
                  <OlogaDownloadExcel
                    data={reportData}
                    headers={headers}
                    buttonLabel="Export Stock List"
                    variant="contained"
                    fileName={`Stock List ${selectedEventName}`}
                    sheetName="Stock List"
                  />
                </Grid>
              </Grid>
            )}
            <br />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Station Name</TableCell>
                    <TableCell>Size</TableCell>
                    <TableCell>Opening Stock</TableCell>
                    <TableCell>Transfer In</TableCell>
                    <TableCell>Transfer Out</TableCell>
                    <TableCell>Final Opening Stock</TableCell>
                    <TableCell>Manual Closing Stock</TableCell>
                    <TableCell>Closing Stock</TableCell>
                    <TableCell>Quantity Sold</TableCell>
                    <TableCell>Diference</TableCell>
                    <TableCell>ACTIONS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportData.map(
                    ({
                      eventProductId,
                      imageUri,
                      productDescription,
                      stationName,
                      productSizeDescription,
                      openingStock,
                      transferIn,
                      transferOut,
                      finalOpeningStock,
                      closingStockManual,
                      closingStock,
                      quantitySold,
                      difference,
                    }) => (
                      <TableRow
                        key={eventProductId + productDescription + transferIn}
                      >
                        <TableCell width={30}>
                          <img
                            src={imageUri}
                            width="30px"
                            height="auto"
                            alt="product-img"
                          />
                        </TableCell>
                        <TableCell>{productDescription}</TableCell>
                        <TableCell>{stationName}</TableCell>
                        <TableCell>{productSizeDescription}</TableCell>
                        <TableCell>{openingStock}</TableCell>
                        <TableCell> {transferIn}</TableCell>
                        <TableCell> {transferOut}</TableCell>
                        <TableCell> {finalOpeningStock}</TableCell>
                        <TableCell>{closingStockManual}</TableCell>
                        <TableCell>{closingStock}</TableCell>
                        <TableCell>{quantitySold}</TableCell>
                        <TableCell>{difference}</TableCell>
                        <TableCell>
                          {
                            <VisibilityOutlinedIcon
                              onClick={() => {
                                setSelectedEventProductMetadata({
                                  vendorId: userContext?.vendorId,
                                  eventId: selectedEventId,
                                  eventName: selectedEventName,
                                  eventProductId: eventProductId,
                                  productName: productDescription,
                                  imageUri: imageUri,
                                  openingStock: transferIn,
                                  finalOpeningStock: transferOut,
                                  closingStock: closingStock,
                                  closingStockManual: closingStockManual,
                                  difference: difference,
                                });
                                setOpenDetailsModal(true);
                              }}
                            />
                          }
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </form>
        </CardContent>
      </Card>
      <StockDetailModal
        open={openDetailsModal}
        onClose={() => {
          setSelectedEventProductMetadata({});
          setOpenDetailsModal(false);
        }}
        stockMetadata={selectedEventProductMetadata}
      />
    </React.Fragment>
  );
};

export default StockList;
